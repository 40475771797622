<!--                                       Attributes -->
<template>
  <div class="columns">
    <div class="column is-4">
      <div class="p-2 bg-sky-800 text-white is-flex is-justify-content-space-between is-uppercase">
        <i class="fa fa-diagram-project mr-2 mt-1 text-sky-500"></i>
        Atribute {{ $root.modules[related_entity].title }}
        <a @click.prevent="masterAttribute()">
          <i class="fa fa-plus-circle text-white"></i>
        </a>
      </div>
      <Tree v-if="categories.list" :items="categories.list" :levels="possible_levels" :flat="true" :node_has_children="{
        'prop': 'type_id',
        'value': 'select'
      }" />
    </div>
    <div class="column is-8">
      <DaForm v-if="form" @submitted="submitClientAttribute" @deleted="deleteAttribute" :form="form" @close="form = null" :closes_form="true">
      </DaForm>
    </div>
  </div>
</template>

<script>
import Tree from "@/components/Tree.vue";
import DaForm from "@/components/daform/index.vue";
import createAttribute from "./forms/create";
import editAttribute from "./forms/edit";
import { useCategoryStore } from "@/stores/category";
import AttributesServices from "@/services/Attribute";
import helpers from "@/helpers.js";
import { onUnmounted, inject } from 'vue'
import socket from '@/socket.js';
import permissionMixin from '@/mixins/permissions-mixin.js'

export default {
  mixins: [permissionMixin],
  components: {
    Tree,
    DaForm,
  },
  setup() {
    let categories = useCategoryStore()
    const toast = inject('toast')
    socket.connect()
    socket.on("welcome", data => {
      console.log('welcome', data)
    })
    socket.on("propagate", data => {
      if (data.action == "create") {
        var a = categories.list
        a.push(data.data)
        categories.list = [...a]
        toast.success('Atributi u krijua nga ' + data.created_by.name)
      }
      if (data.action == "edit") {
        categories.list = [...categories.list.map(e => {
          if (e.guid == data.data.guid) {
            return data.data
          }
          return e
        })]
        toast.success('Atributi u modifikua nga ' + data.created_by.name)
      }
    })
    onUnmounted(() => {
      socket.off('welcome')
      socket.off('propagate')
      socket.disconnect()
    })
    return {
      categories
    }
  },
  data() {
    return {
      form: null,
      parent_cat: null,
      related_entity: "",
      possible_levels: 2,
    };
  },
  watch: {
    "$route.params"() {
      this.related_entity = this.$route.params.related_entity
      this.getInitialData()
    },
    "categories.current"() {
      if (this.categories.mode == "add") {
        // if (
        //   (this.am_allowed('product.can_create_product_attributes') && this.$route.path == '/attributes/products') ||
        //   (this.am_allowed('customer.can_create_customer_attributes') && this.$route.path == '/attributes/customers')
        // ) {
        this.createAttribute();
        // } else {
        //   this.$toast.error('Ju nuk keni te drejta per te krijuar nje atribut.')
        // }
      }

      if (this.categories.mode == "edit") {
        // if (
        //   (this.am_allowed('product.can_edit_product_attributes') && this.$route.path == '/attributes/products') ||
        //   (this.am_allowed('customer.can_edit_customer_attributes') && this.$route.path == '/attributes/customers')
        // ) {
        this.editAttribute();
        // } else {
        //   this.$toast.error('Ju nuk keni te drejta per te modifikuar nje atribut.')
        // }
      }

    },
  },
  methods: {
    async getInitialData() {
      if (this.related_entity) {
        this.categories.flat_list = []
        this.categories.flat_list = await AttributesServices.getAttributes(this.related_entity)
        this.categories.list = [];
        this.categories.list = await AttributesServices.getAttributeTree(this.categories.flat_list, "id")
        this.$root.setCurrentRoute("kliente-atribute");

        let attr_types = await AttributesServices.getAttributeTypeList()
        this.categories.attribute_types = attr_types.map(t => {
          (t.id = t.guid),
            (t.label = t.name)
          return t
        })
      }
    },
    deleteAttribute(a) {
      this.categories.flat_list = [...this.categories.flat_list.filter(at => {
        var found = false
        a.map(attr => {
          if (attr.descendant == at.guid) found = true
        })
        return !found
      })]
      this.categories.list = helpers.toTree(this.categories.flat_list, "id")
      this.form = null
    },
    async submitClientAttribute(a) {
      // set parent attribute
      a = {
        ...a,
        parent_id: this.parent_cat,
        guid:
          this.categories.current.guid &&
            this.categories.current.guid != this.parent_cat
            ? this.categories.current.guid
            : null,
      };

      let current_attribute_process = await AttributesServices.getAttributeProcess(
        a, this.categories.mode, this.related_entity
      )
      var notify_text = ""
      if (this.categories.mode == "add") {
        var new_attribute_proccess = {
          ...current_attribute_process,
          id: current_attribute_process.guid,
          label: current_attribute_process.name,
          collapsed: true
        }
        this.categories.flat_list.push(new_attribute_proccess)
        this.categories.list = [];
        this.categories.list = await AttributesServices.getAttributeTree(this.categories.flat_list, "id")
        socket.emit("propagate", { entity: this.related_entity, action: "create", data: current_attribute_process })
        notify_text = "Atributi u krijua me sukses!"
      }
      else if (this.categories.mode == "edit") {
        this.categories.flat_list = [...this.categories.flat_list.map(at => {
          if (at.guid == current_attribute_process.guid){ 
            at = {
              ...current_attribute_process, 
              id: current_attribute_process.guid, 
              label: current_attribute_process.name, collapsed: true
            }
          }
          return at
        })]
        
        this.categories.list = JSON.parse(JSON.stringify(helpers.toTree(this.categories.flat_list, "id")))
        socket.emit("propagate", { entity: this.related_entity, action: "edit", data: current_attribute_process })
        notify_text = "Atributi u modifikua me sukses!"
      }
      this.$toast.success(notify_text)
      this.form = null
    },
    createAttribute() {
      this.form = { loading: true }
      // set parent attribute
      this.parent_cat = this.categories.current.guid;
      //load form
      setTimeout(() => {
        this.form = {
          ...createAttribute.form(this.categories, this.related_entity),
        };
      });
    },
    editAttribute() {
      this.form = { loading: true }
      this.parent_cat = this.categories.current.parent_id;
      setTimeout(() => {
        this.form = {
          ...editAttribute.form(this.categories, this.related_entity),
        };
      });
    },
    masterAttribute() {
      // if (
      //   (this.am_allowed('product.can_create_product_attributes') && this.$route.path == '/attributes/products') ||
      //   (this.am_allowed('customer.can_create_customer_attributes') && this.$route.path == '/attributes/customers')
      // ) {
      this.categories.current = {};
      this.categories.mode = "add";
      this.createAttribute();
      // } else {
      //   this.$toast.error('Ju nuk keni te drejta per te modifikuar nje atribut.')
      // }
    },
  },
  async created() {
    // if (
    //   this.related_entity == 'products' && !this.am_allowed('product.can_see_product_attributes') ||
    //   this.related_entity == 'customers' && !this.am_allowed('customer.can_see_customer_attributes')
    // ) {
    //   window.location.href = '/home'
    // }

    this.related_entity = this.$route.params.related_entity
    this.getInitialData()
  },
  onBeforeDestroy() {
    socket.disconnect();
  },
};
</script>

<style lang="scss" scoped></style>
