import Api from "@/services/Api";
import helpers from "@/helpers.js";

export default {
    async getAttributes(related_entity) {
        var attribute_list = []
        await Api(true).post("attribute/attribute_list", {related_entity: related_entity})
        .then(r => {
            attribute_list = r.data.map(e => {
                e.id = e.guid
                e.label = e.name
                e.collapsed = true
                return e
            }),
            "id"
        })
        return attribute_list
    },

    async getAttributeTree(flat_list, relation) {
        return helpers.toTree(flat_list, relation)
    },

    async getAttributeTypeList() {
        var attribute_types = []
        await Api(true).post("attribute_type/attribute_type_list", {})
        .then(r => {
            attribute_types = r.data
        })
        return attribute_types
    },

    async getAttributeProcess(attribute, mode, related_entity) {

        await Api(true).post(
            mode == "add"
            ? "attribute/create/" + related_entity
            : "attribute/edit/" + related_entity, 
            attribute
        )     
        .then(r => {
        attribute = r.data
        })
        
        return attribute
    },
}