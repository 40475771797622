export default {
    form: (attr, related_entity) => {
        var type = {}
        var showInWeb = {} 
        var main_specifications = {} 
        var showcase_specification = {} 
        if(attr.current.parent_id == null){
            if(attr.current.children) 
                type = {
                    value: attr.current.type_id,
                    name: 'type_id',
                    label: 'Tipi',
                    type: 'select-field',
                    disabled: true,
                    disabled_text: attr.current.type_id,
                    options: attr.attribute_types
                }
            else 
                type = {
                    value: attr.current.type_id,
                    name: 'type_id',
                    label: 'Tipi',
                    type: 'select-field',
                    options: attr.attribute_types
                }

            if (related_entity == 'products') {
                showInWeb = {
                    value: attr.current.show_web,
                    name: 'show_web',
                    label: 'Shfaq ne web',
                    style: "min-width: 2%",
                    type: 'checkbox-field'
                }
                main_specifications = {
                    value: attr.current.show_main_specification,
                    name: 'show_main_specification',
                    label: 'Shfaq tek statistikat kryesore',
                    style: "min-width: 2%",
                    type: 'checkbox-field'
                }
                showcase_specification = {
                    value: attr.current.show_showcase_specification,
                    name: 'show_showcase_specification',
                    label: 'Shfaq ne specifika vetrine',
                    style: "min-width: 2%",
                    type: 'checkbox-field'
                }
            }
        }
        else {
            type = {
                // value: attr.current.type_id,
                // name: 'type_id',
                // label: 'Tipi',
                // type: 'select-field',
                // disabled: true,
                // disabled_text: attr.current.type_id,
                // options: attr.attribute_types
            }
        }
    
        return {
            name: "<span class='mr-1'>Modifiko</span> <b>" + 
                attr.current.name + "</b>",
            submit: {
                label: "Modifiko",
                action: "submitCreateAttribute"
            },
            delete: {
                guid: attr.current.guid,
                model: 'Attribute',
                api: 'attribute/delete/' + related_entity,
                text: 'Jeni te sigurte qe doni ta fshini kete atribut?'
            },
            fields: [
                [
                    {
                        value: attr.current.code,
                        name: 'code',
                        label: 'Kodi',
                        type: 'text-field',
                        unique: {
                            model: 'attribute',
                            field: 'code',
                            where: [
                                {
                                    column: 'related_entity',
                                    condition: '=',
                                    value: related_entity
                                },
                                {
                                    column: 'guid',
                                    condition: '!=',
                                    value: attr.guid
                                }
                            ]
                        },
                        left_icon: 'fa-solid fa-hashtag',
                        style: "min-width: 45%",
                        required: true
                    },
                    {
                        value: attr.current.name,
                        name: 'name',
                        label: 'Emri',
                        type: 'text-field',
                        style: "min-width: 45%",
                        required: true,
                        required_text: 'Kjo fushe eshte e detyrueshme'
                    },
                    {
                        value: attr.current.active,
                        name: 'active',
                        label: 'Aktiv',
                        type: 'checkbox-field',
                        style: "min-width: 2%",
                        unique: true
                    }
                ],
                {
                    value: attr.current.description,
                    name: 'description',
                    label: 'Pershkrimi',
                    type: 'textarea-field'
                },
                
                [
                    type,
                    showInWeb,
                    main_specifications,
                    showcase_specification
                ]
            ]
        }
    }
}
