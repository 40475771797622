export default {
    form: (attr, related_entity) => {
        var ob = {}
        var type = {} 
        var showInWeb = {} 
        var main_specifications = {} 
        var showcase_specification = {} 
        if(attr.current.parent_id == null){
            if(attr.current.children && attr.current.children.length > 0) {
                type = {
                   
                }
            }
            else {
                if(!attr.children && attr.current.type_id != 'select')
                    type = {
                        value: '',
                        name: 'type_id',
                        label: 'Tipi',
                        type: 'select-field',

                        options: attr.attribute_types,
                    }
								else { // other fields
									type = {
										value: attr.current.type_id,
										name: 'type_id',
										label: 'Tipi',
										type: 'select-field',
										disabled: true,
										disabled_text: attr.current.type_id,
										options: [],

									}
								}
                if (related_entity == 'products') {
                    showInWeb = {
                        value: 0,
                        name: 'show_web',
                        label: 'Shfaq ne web',
                        style: "min-width: 2%",
                        type: 'checkbox-field'
                    }
                    main_specifications = {
                        value: 0,
                        name: 'show_main_specification',
                        label: 'Shfaq tek statistikat kryesore',
                        style: "min-width: 2%",
                        type: 'checkbox-field'
                    }
                    showcase_specification = {
                        value: 0,
                        name: 'show_showcase_specification',
                        label: 'Shfaq ne specifika vetrine',
                        style: "min-width: 2%",
                        type: 'checkbox-field'
                    }
                }
            }
        }
        else {
            type = {
                value: attr.current.type_id,
                name: 'type_id',
                label: 'Tipi',
                type: 'select-field',
                disabled: true,
                disabled_text: attr.current.type_id,
                options: [],

            }
        }

        ob = {
            name: "Krijo" + (attr.current.guid ? " Sub" : "")
            + " Atribute" + (attr.current.name ? ": <i>" + attr.current.name +"</i>" : ''),
            submit: {
                label: "Krijo",
                action: "submitCreateAttribute"
            },
            fields: [
                [
                    {
                        value: '',
                        name: 'code',
                        label: 'Kodi',
                        type: 'text-field',
                        unique: {
                            model: 'attribute',
                            field: 'code',
                            where: [
                                {
                                    column: 'related_entity',
                                    condition: '=',
                                    value: related_entity
                                }
                            ]
                        },
                        left_icon: 'fa-solid fa-hashtag',
                        style: "min-width: 45%",
                        required: true
                    },
                    {
                        value: '',
                        name: "name",
                        label: 'Emri',
                        type: 'text-field',
                        style: "min-width: 45%",
                        required: true,
                        required_text: 'Kjo fushe eshte e detyrueshme'
                    },
                    {
                        value: 0,
                        name: 'active',
                        label: 'Aktiv',
                        style: "min-width: 2%",
                        type: 'checkbox-field'
                    }
                ],
                {
                    value: '',
                    name: 'description',
                    label: 'Pershkrim',
                    type: 'textarea-field',
                    vif: [
                        { field: 'name', value_not: ""}
                    ]
                },
                 
                [type, showInWeb, main_specifications, showcase_specification]
            ]
        }
        return ob
    }
}
